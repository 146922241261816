<template>
  <elasticTableLogs :data="data" :loading="loading" :link-keys="linkKeys" :keys="keys" @fetch="onFetchData" />
</template>

<script>
import ElasticTableLogs from "@/core/components/ElasticTableLogs/index";
import logsMixin from "@/core/components/ElasticTableLogs/logsMixin";
import { ApiService } from "@/api";
export default {
  name: "OperatorsLogs",
  components: { ElasticTableLogs },
  mixins: [logsMixin],
  data() {
    return {
      keys: ["client_name", "domain", "id_operator", "short_name", "status", "time_zone", "timezone"],
      linkKeys: {
        id_operator: `${process.env.BASE_URL}operators/edit/`,
      },
    };
  },
  methods: {
    fetchLogs: ApiService.logs.getOperatorLogs,
  },
};
</script>
