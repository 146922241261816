<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <el-tabs>
          <el-tab-pane label="Список операторов">
            <search :query.sync="query" @search="search" />
            <operators-table :operators="items" />
            <el-pagination
              class="list__pagination"
              layout="prev, pager, next"
              :page-size="count"
              :total="total"
              :current-page="page"
              @current-change="changePage"
            />
          </el-tab-pane>
          <el-tab-pane label="Логи синхронизации">
            <operators-logs />
          </el-tab-pane>
        </el-tabs>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button
        class="page__active_button"
        icon="el-icon-plus"
        type="primary"
        @click="$router.push({ name: 'operators:create' })"
      >Добавить</el-button>
    </el-footer>
  </el-container>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import routeList from "@/core/mixins/routeList";
import OperatorsTable from "./OperatorsTable";
import Search from "@/components/Search";
import OperatorsLogs from "@/components/Operators/components/OperatorsList/OperatorsLogs";

export default {
  name: "OperatorsList",
  components: {
    OperatorsLogs,
    OperatorsTable,
    Search,
  },
  mixins: [routeList],
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapState("operators", {
      items: (state) => state.operators.data,
      total: (state) => state.operators.total,
      count: (state) => state.operators.count,
    }),
    ...mapGetters("operators", ["getQuery"]),
  },
  methods: {
    ...mapActions("operators", {
      getList: "GET_OPERATORS",
    }),
    ...mapMutations("operators", {
      setQuery: "SET_QUERY",
      clearList: "SET_LIST_OPERATORS",
    }),
  },
};
</script>

<style lang="scss">
.list,
.list__pagination {
  margin: 20px 0;
  text-align: center;
}
.el-icon-error {
  color: #fa5555;
}
.el-icon-success {
  color: #67c23a;
}
.el-icon-warning {
  color: #409eff;
}
.el-table th > .cell {
  word-break: break-word;
}
.link {
  line-height: 1;
  cursor: pointer;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  color: #409eff;
  text-decoration: underline;
}
</style>
